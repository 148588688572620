export const CALCULATION_DEFINITIONS = {
  probeabrechnung: { icon: 'experiment', captionPlural: 'Probeabrechnungen' },
  schaetzung: { icon: 'calculate', captionPlural: 'Schätzungen' },
  abrechnung: { icon: 'spellcheck', captionPlural: 'Abrechnungen' },
  rueckrechnung: { icon: 'manage_history', captionPlural: 'Rückrechnungen' },
  jaehrlichesLohnkonto: {
    icon: 'event_available',
    captionPlural: 'Jährliche Lohnkonten',
  },
  allgemeineAbrechnungsparameter: {
    icon: 'library_books',
    captionPlural: 'Allgemeine Abrechnungsparameter',
  },
  sozialversicherungswerte: {
    icon: 'domain',
    captionPlural: 'Sozialversicherungswerte',
  },
  sozialkassenparameter: {
    icon: 'maps_home_work',
    captionPlural: 'Sozialkassenparameter',
  },
  durchschnittslohn: { icon: 'functions', captionPlural: 'Durchschnittslöhne' },
  vortragswertArbeitnehmer: {
    icon: 'settings_accessibility',
    captionPlural: 'Vortragswerte Arbeitnehmer',
  },
  vortragswerteUrlaubskasse: {
    icon: 'holiday_village',
    captionPlural: 'Vortragswerte Urlaubskasse',
  },
  vortragswerteAZK: { icon: 'wysiwyg', captionPlural: 'Vortragswerte AZK' },
  vortragswerteSteuerSV: {
    icon: 'savings',
    captionPlural: 'Vortragswerte EBZ Eintritt AN',
  },
  lohnkonto: { icon: 'badge', captionPlural: 'Lohnkonten' },
};
